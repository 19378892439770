// Packages imports
import {
  AppShell,
  Burger,
  Button,
  Container,
  Group,
  Transition,
  rem,
  Center,
  Flex,
  Title,
  Text,
  Card,
  BackgroundImage,
  Image,
  Loader,
  Stack,
  Paper,
  Skeleton,
  Badge,
  ScrollArea,
  ActionIcon,
  Divider,
  Switch,
} from '@mantine/core';

// Contexts imports
import { useLanguage } from './common/language/Language';

import { PoweredBy } from './common/components/PoweredBy';

import { GlobalHeader } from './common/components/navigation/GlobalHeader';

import { ContentRoutes } from './common/content/ContentRoutes';

import { UserMenu } from './common/security/User';

import { GlobalSecurity } from './common/security/GlobalSecurity';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  duotone,
  regular,
  thin,
} from '@fortawesome/fontawesome-svg-core/import.macro';
import {
  useDisclosure,
  useLocalStorage,
  useOs,
  useViewportSize,
  useWindowScroll,
} from '@mantine/hooks';

import { useEffect, useState } from 'react';

import * as Sentry from '@sentry/react';
import { Landing } from './pages/Landing';
import { NotFound } from './pages/NotFound';
import { TrainingModule } from './modules/training/TrainingModule';
import { Exagard_Colors, GlobalAppsettings } from './GlobalSettings';
import logo_white from './styles/images/logo_trans.png';
import x_color from './styles/images/exagard_x_color.png';
import x from './styles/images/exagard_x.png';
import { useContent } from './common/content/useContent';
import { renderMarkdown } from './common/content/RenderMarkdown';
import { LanguageControl } from './common/language/LanguageControl';
import { usePWA } from './common/pwa/pwa';
import { QRScanner } from './modules/app/QRScanner';
import { TrainingList } from './modules/training/TrainingList';
import { QRScanner2 } from './modules/app/QRScanner2';
import useSWR from 'swr';
import { fetcher } from './common/Fetcher';
import { ResultBase } from './common/api/common';
import { TrainingItem } from './modules/training/api/TrainingApi';
import { ResourceModule } from './modules/resource/ResourceModule';
import { useSubscribe } from './common/pubsub/usePubsub';
import { Start } from './pages/Start';
import { TrainingStart } from './modules/training/TrainingStart';

export type AppProps = {};

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const App = ({}: AppProps) => {
  const lang = useLanguage();

  const os = useOs();
  const isMobile = os === 'ios' || os === 'android';

  const { height, width } = useViewportSize();

  const navigate = useNavigate();

  const [mobileOpened, { toggle: toggleMobile }] = useDisclosure();
  const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure();

  const about = useContent({
    contentID: '5QBC0xFG3EHf1PqmWBHnJ1',
    locale: lang.locale(),
    preview: false,
  });
  const privacy = useContent({
    contentID: '4TAU5cbuBJxtAArmDhHAkN',
    locale: lang.locale(),
    preview: false,
  });

  const [editLock, setEditLock] = useState(false);
  const [allowEdit, setAllowEdit] = useState(false);

  // useSubscribe('allow-edit', (msg: string, value: boolean) => {
  //     setAllowEdit(value);
  // });

  return (
    <AppShell
      header={{ height: 64 }}
      navbar={{
        width: 450,
        breakpoint: 'sm',
        collapsed: { mobile: !mobileOpened, desktop: !desktopOpened },
      }}
      // footer={{ height: 75 }}
      padding='md'
    >
      <AppShell.Header className=''>
        <Group
          gap={4}
          justify='space-between'
          wrap='nowrap'
          className={'h-16 border-t-8 border-[#64A70B]'}
          bg={Exagard_Colors._black}
          px='md'
        >
          <Burger
            opened={mobileOpened}
            onClick={toggleMobile}
            hiddenFrom='sm'
            size='md'
            color='white'
          />
          <Burger
            opened={desktopOpened}
            onClick={toggleDesktop}
            visibleFrom='sm'
            size='md'
            color='white'
          />
          <Group wrap='nowrap' grow gap={0} className='-ml-4' justify='center'>
            <Image
              src={logo_white}
              // width={200}
              //height={50}
              fit='contain'
              className='h-6 max-h-6'
            />
            {/* <Text className='text-white font-bold text-xl border-l-2 ml-4 px-4'>
              EVA
            </Text> */}
          </Group>
          <Group wrap='nowrap' gap={0} justify='flex-end'>
            <LanguageControl renderList={false} />

            {allowEdit ? (
              <Button
                variant='transparent'
                onClick={() => setEditLock(!editLock)}
              >
                <FontAwesomeIcon
                  icon={editLock ? duotone('lock-open') : duotone('lock')}
                  size='2xl'
                  color={'white'}
                />
              </Button>
            ) : (
              <div> </div>
            )}
          </Group>
        </Group>
      </AppShell.Header>
      <AppShell.Navbar style={{ zIndex: 50 }}>
        <ScrollArea h={height} className='px-4'>
          <Stack>
            <Group className='min-h-8 mt-8'>
              <Button
                color={Exagard_Colors._green}
                leftSection={
                  <FontAwesomeIcon icon={regular('home')} size='2xl' />
                }
                variant='subtle'
                onClick={() => {
                  navigate('/');
                  toggleMobile();
                  toggleDesktop();
                }}
              ></Button>

              <Button
                color={Exagard_Colors._green}
                leftSection={
                  <FontAwesomeIcon icon={regular('newspaper')} size='2xl' />
                }
                variant='subtle'
                onClick={() => {
                  navigate('/news');
                  toggleMobile();
                  toggleDesktop();
                }}
              >
                {lang.Text('News')}
              </Button>

              <Button
                color={Exagard_Colors._green}
                leftSection={
                  <FontAwesomeIcon icon={regular('rocket-launch')} size='2xl' />
                }
                variant='subtle'
                onClick={() => {
                  toggleMobile();
                  toggleDesktop();
                  navigate('/rounds');
                }}
              >
                {lang.Text('Rounds')}
              </Button>
            </Group>

            <Title order={4}>{lang.Text('Language')}</Title>
            <Divider variant='dotted' />
            <Group className='min-h-8'>
              <LanguageControl renderList={true} />
            </Group>
            <Title order={4}>{lang.Text('Privacy')}</Title>
            <Divider variant='dotted' />
            <div className='min-h-8 text-sm'>
              {renderMarkdown(privacy?.contentItem?.copy || '')}
            </div>
            <Switch
              defaultChecked
              label={lang.Text('I agree to tracking my usage')}
            />
            <Title order={4}>{lang.Text('Settings')}</Title>
            <Divider variant='dotted' />
            <Group className='min-h-8'>
              <Button
                variant='subtle'
                color={Exagard_Colors._green}
                leftSection={
                  <FontAwesomeIcon icon={regular('refresh')} size='lg' />
                }
                onClick={() => window.location.reload()}
              >
                {lang.Text('Refresh')}
              </Button>
              {/* <Button variant='filled' color={Exagard_Colors._dark_green} onClick={() => setBadge(1)}>Test</Button> */}
            </Group>
            <Title order={4}>{lang.Text('About')}</Title>
            <Divider variant='dotted' />
            <div className='min-h-16 text-sm'>
              {renderMarkdown(about?.contentItem?.copy || '')}
            </div>
            <Divider variant='dotted' />
            <Center>
              <Text className='text-gray-300'>{GlobalAppsettings.AppId}</Text>
            </Center>
          </Stack>
        </ScrollArea>
      </AppShell.Navbar>
      <AppShell.Main
        bg={Exagard_Colors._light_gray}
        className='px-0 py-10'
        //p={0}
      >
        {/* Content */}
        <SentryRoutes>
          <Route
            path='/'
            element={
              <ScrollArea h={height}>
                <Start />
              </ScrollArea>
            }
          />
          <Route
            path='/news'
            element={
              <ScrollArea h={height}>
                <Landing />
              </ScrollArea>
            }
          />
          {/* <Route
            path='/start/:id'
            element={
              <ScrollArea h={height}>
                <TrainingStart  />
              </ScrollArea>
            }
          /> */}
          <Route
            path='rounds'
            element={
              <ScrollArea h={height}>
                <TrainingList orgId={'95342662-930f-4535-aa1a-4a2e0fe0c56b'} />
              </ScrollArea>
            }
          />
          <Route
            path='round/:id/:group'
            element={
              <TrainingModule editMode={editLock} setAllowEdit={setAllowEdit} />
            }
          />
          <Route
            path='round/:id'
            element={
              <TrainingModule editMode={editLock} setAllowEdit={setAllowEdit} />
            }
          />
          <Route
            path='training/:id'
            element={
              <TrainingModule editMode={editLock} setAllowEdit={setAllowEdit} />
            }
          />
          <Route
            path='training/:id/:group'
            element={
              <TrainingModule editMode={editLock} setAllowEdit={setAllowEdit} />
            }
          />
          <Route
            path='resource/:resourceId'
            element={
              <ScrollArea h={height}>
                <ResourceModule />
              </ScrollArea>
            }
          />

          {ContentRoutes({
            contentType: 'evaPage',
            locale: lang.locale(),
            preview: false,
          })}
          {ContentRoutes({
            contentType: 'news',
            locale: lang.locale(),
            preview: false,
          })}

          <Route path='*' element={<NotFound />} />
        </SentryRoutes>

        {/* <Image
          className='fixed left-[150px] bottom-0 right-0'
          style={{ zIndex: -1 }}
          //h={450}
          width={'100vw'}
          fit={'contain'}
          src={x}
        /> */}
      </AppShell.Main>
    </AppShell>
  );
};

export default App;
